<template>
    <div class="flex justify-content-center">
        <img class="logo-img" src="layout/images/duon_azul.png" alt="Logo da empresa, formando a palavra Duon" />
    </div>
    <div class="flex justify-content-center">
        <p class="msg-bem-vindo">Bem-vindo ao seu atendimento</p>
    </div>
    <div class="flex justify-content-center msg-instrucao">
        Digite seu cpf no campo abaixo para fazer o seu <span class="text-green-500 ml-2">Check-in</span>
    </div>
    <div class="flex flex-column align-items-center">
        <InputMask
            id="cpf"
            class="input-cpf"
            v-model="form.cpf"
            mask="999.999.999-99"
            :unmask="true"
            placeholder="Informe seu CPF"
            @keyup.enter="validar"
            :class="{ 'p-invalid': submitted && !form.cpf }"
        />
        <small class="p-error mt-2 msg-erro" v-if="submitted && !form.cpf">Ops, informe o seu CPF a cima.</small>
    </div>
    <div class="flex justify-content-center">
        <Button
            :loading="loading"
            iconPos="right"
            iconClass="text-3xl"
            icon="pi pi-search"
            @click="validar"
            label="Buscar"
            class="p-button-rounded p-button-success btn-validar"
        >
        </Button>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';

export default {
    data() {
        return {
            form: {},
            loading: false,
            dados: {},
            submitted: false
        };
    },
    methods: {
        async validar() {
            this.submitted = true;
            this.loading = true;
            try {
                const path = `/atendimento_check_in/find-one`;
                const dados = await getClientBase().get(path, {
                    params: {
                        cpf: this.form.cpf
                    }
                });

                this.$router.push({ name: 'Dados Check-In', params: { dadosPaciente: JSON.stringify(dados) } });
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: error?.response?.data.message,
                    detail: 'Caso houve algum mal entendido, dirija-se para a recepção',
                    life: 8000
                });
            }
            this.loading = false;
        }
    }
};
</script>
<style scoped lang="scss">
.msg-bem-vindo {
    font-size: 4vw;
    font-weight: 400;
    margin: 0px;
}

.logo-img {
    width: 20vw;
    padding: 5px;
    margin: 20px;
}

.msg-instrucao {
    font-size: 2vw;
    margin-bottom: 0px;
}

.input-cpf {
    width: 55vw;
    height: 6vw;
    font-size: 2vw;
    margin-top: 2vw;
}

.btn-validar {
    width: 15vw;
    height: 4vw;
    font-size: 2vw;
    margin-top: 2vw;
}

.msg-erro {
    font-size: 1vw;
}

@media only screen and (max-width: 526px) {
    .msg-bem-vindo {
        font-size: 4vw;
    }

    .msg-instrucao {
        font-size: 2vw;
    }
}
</style>
